import Layout from "@/components/Layout"
import React from "react"
import ListNote from "@/components/ListNote"
import { graphql } from "gatsby"
import { SITE_TITLE } from "../../config"

const CategoryTemplate = ({ data, pageContext }) => {
    const { edges } = data.allMdx
    const {
        category,
        // slug,
        currentPage,
        // prevPagePath,
        // nextPagePath,
        // hasPrevPage,
        // hasNextPage,
    } = pageContext
    const pageTitle =
        currentPage > 0
            ? `${category} - Page ${currentPage} - ${SITE_TITLE}`
            : `${category} - ${SITE_TITLE}`

    return (
        <Layout title={pageTitle}>
            <h1>{category}</h1>
            <ListNote edges={edges} />
            {/* {(hasPrevPage || hasNextPage) && (
        <Pagination
          prevPagePath={prevPagePath}
          nextPagePath={nextPagePath}
          hasPrevPage={hasPrevPage}
          hasNextPage={hasNextPage}
        />
      )} */}
        </Layout>
    )
}

export const query = graphql`
    query CategoryPage($category: String) {
        allMdx(
            filter: {
                frontmatter: { publish: { ne: false } }
                fields: { category: { eq: $category } }
            }
            sort: { order: DESC, fields: fields___date }
        ) {
            edges {
                node {
                    ...postList
                }
            }
        }
    }
`

export default CategoryTemplate
